<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-img
          max-width="600"
          src="@/assets/sticker_logo.png"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        align-self="end"
      >
        <p id="tagline" class="text-md-h4 ml-2 ml-md-4 mb-6">
          Awarded Podcast Network of the Year at the 2024 ADWEEK Audio Awards
        </p><p id="tagline" class="text-md-h4 ml-2 ml-md-4 mb-0">
          Unparalleled access and insights into the heart of social justice in America
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-16">
      <v-col class="pb-0">
        <h2 class="text-h6 text-md-h5 pl-2 text-uppercase primary-text" style="line-height: 1.1em">
          Featured Podcast: <br class="d-md-none"> {{ featured.series_title }}</h2>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" md="6">
        <div class="pl-2"><a :href="'/podcast/' + this.featured.slug" class="large-font">{{featured.title}}</a></div>
        <div class="text-body-1 pl-2 accent-text">{{ featured.published_at | moment }}</div>
        <p class="text-h4 pl-2">{{featured.excerpt}}</p>
      </v-col>
      <v-col cols="12" md="6" class="ml-6 ml-md-0">
        <player :audioLink="featured.audio_link"></player>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 id="shows-title" class="text-md-h6 pl-1 pt-12">SHOWS</h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="(cover, i) in covers"
        :key="i"
        :class="{
          custom12cols: $vuetify.breakpoint.xs,
          custom5cols: $vuetify.breakpoint.smAndUp
        }"
        cols="12"
        md="3"
        xclass="pa-0 pl-4 pt-2"
        style="margin-bottom: 32px"
      >
        <v-card
          style="background: transparent"
          :href="'/' + cover.slug"
          hover
        >
          <v-img
            max-width="240"
            style="border: 1px solid #878787"
            :src="'/covers/' + cover.identifier + '_cover.png'"
            class="mx-auto"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    featured: {
      series_title: '',
      series_slug: '',
      slug: '',
      title: '',
      excerpt: '',
      published_at: '',
      audio_link: "",
    },
    covers: [],
    coversOld: [
      { to: "/war-on-drugs", src: "WD_cover.png"},
      { to: "/with-maggie-freleng", src: "MF_cover.png"},
      { to: "/bone-valley", src: "BV_cover.png"},
      { to: "/with-jason-flom", src: "WC_cover.png"},
      { to: "/righteous-convictions", src: "RC_cover.png"},
      { to: "/false-confessions", src: "FC_cover.png"},
      { to: "/junk-science", src: "JS_cover.png"},
    ],
  }),

  filters: {
    moment: function (date) {
      return moment(date).format('DD MMMM, yyyy');
    }
  },

  mounted () {
    axios
      .get('/api/v1/covers')
      .then((response) => {
        //console.log(response)
        this.covers = response.data.covers
        //this.covers = response.data.covers.filter((cover) => { return cover.slug !== 'with-lauren-bright-pacheco'})
      })
      .catch((error) => {
        console.log(error)
      })
    axios
      .get('/api/v1/featured')
      .then((response) => {
        //console.log(responsenot-found/)
        this.featured = response.data.featured
      })
      .catch((error) => {
          console.log(error)
      })
  }
}
</script>
<style>
.custom12cols {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}
.custom7cols {
  width: 14%;
  max-width: 14%;
  flex-basis: 14%;
}
.custom5cols {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
#tagline {
  font-family: "Geogrotesque", sans-serif !important;
  line-height: 39.1px;
  font-size: 32px !important;
  letter-spacing: 0.25px;
}
#shows-title {
  font-family: "Geogrotesque", sans-serif !important;
  font-size: 1.25rem !important;
}
 .accent-text {
  color: #878787;
}
.primary-text {
  font-family: "Geogrotesque", sans-serif !important;
  color: #EA1301;
}
 a.large-font {
  text-decoration: none;
  color: #FDFDFD !important;
  font-size: 32px;
  line-height: 0.9em;
}
a.large-font:hover {
  color: #878787 !important;
}
</style>
